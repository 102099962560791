import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  CardMedia,
  Container,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import FilterIcon from "@material-ui/icons/DeleteForever";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import DataTable from "./DataTable";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { TerminalDialog } from "./TerminalDialog";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import { filter } from "./filter";

// const testData = require("./data.json");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#FEB913", // St John Yellow
    minHeight: 72,
    justifyContent: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchIcons: {
    marginLeft: theme.spacing(0.5),
  },
  logo: {
    height: 40,
    width: 50,
    backgroundSize: "contain",
  },
  main: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  footer: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  splitter: {
    height: `calc(100vh - ${theme.spacing(9)}px)`,
  },
  paneContent: {
    marginTop: 5,
  },
}));

export default function Main({ config, io, onSignOut, onError }) {
  const timeoutHandleRef = useRef();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const liveFilterDefaults = {
    island: "both",
    location: "",
    ric: "",
    level: "",
    message: "",
  };

  const [liveFilterOptions, setLiveFilterOptions] = useState(liveFilterDefaults);

  const [searchOptions, setSearchOptions] = useState({
    startDate: moment(),
    endDate: moment(),
    type: "rdo",
    location: "",
    ric: "",
    message: "",
  });

  const [data, setData] = useState({
    searchEntries: [],
    liveEntriesRdo: [],
    liveEntriesFilteredRdo: [],
    liveEntriesSat: [],
    liveEntriesFilteredSat: [],
  });

  const [liveView, setLiveView] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);
  const [terminalText, setTerminalText] = useState("");
  const [searchRequested, setSearchRequested] = useState(false);

  // use ref here to avoid stale state <-- great comment but you don't know why! (me later)
  const dataRef = useRef(data);
  const liveFilterOptionsRef = useRef(liveFilterOptions);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    liveFilterOptionsRef.current = liveFilterOptions;
    const rdoFiltered = addDelta(filter(data.liveEntriesRdo, liveFilterOptions));
    const satFiltered = addDelta(filter(data.liveEntriesSat, liveFilterOptions));
    const { liveEntriesFilteredRdo, liveEntriesFilteredSat, ...rest } = dataRef.current;
    setData({ liveEntriesFilteredRdo: rdoFiltered, liveEntriesFilteredSat: satFiltered, ...rest });
  }, [liveFilterOptions]);

  useEffect(() => {
    if (searchRequested) {
      const { ric, level, message, location, type, startDate: sDate, endDate: eDate } = searchOptions;

      const startDate = sDate.format("YYYY-MM-DD");
      const endDate = eDate.format("YYYY-MM-DD");

      const filters = ric || level || location || message ? { ric, level, location, message } : undefined;

      io.emit("query-load-log", { startDate, endDate, filters, type });
    }
  }, [searchRequested, searchOptions, io]);

  useEffect(() => {
    if (isTerminalOpen) {
      io.on("data-received", (data) => {
        setTerminalText(data);
      });
    } else {
      io.off("data-received");
    }
  }, [isTerminalOpen, io]);

  useEffect(() => {
    //setData({ ...data, entries: addDelta(testData) });

    io.on("result-get-latest", ({ rdo, sat }) => {
      clearTimeout(timeoutHandleRef.current);
      const rdoPlusDelta = addDelta(rdo);
      const satPlusDelta = addDelta(sat);
      setData({
        ...dataRef.current,
        liveEntriesRdo: rdo,
        liveEntriesSat: sat,
        liveEntriesFilteredRdo: rdoPlusDelta,
        liveEntriesFilteredSat: satPlusDelta,
      });
      setIsBusy(false);
    });

    io.on("result-load-log", ({ rdo, sat }) => {
      clearTimeout(timeoutHandleRef.current);
      setSearchRequested(false);

      if (rdo) {
        setData({ ...dataRef.current, searchEntries: addDelta(rdo) });
      }

      if (sat) {
        setData({ ...dataRef.current, searchEntries: addDelta(sat) });
      }
      setIsBusy(false);
    });

    io.on("response-download-raw", (result) => {
      const { error, url } = result;

      if (error) {
        onError(error);
        return;
      }

      const a = document.createElement("a");
      a.href = url;
      a.type = "application/zip";
      a.click();
    });

    io.on("packet-parsed", (packet) => {
      const { maxLiveEntries } = config;
      const { type, ...rest } = packet;

      switch (type) {
        default:
          console.log(`type ${type} not implemented`);
          break;
        case "rdo":
          const { liveEntriesRdo } = dataRef.current;
          liveEntriesRdo.unshift(rest);
          if (liveEntriesRdo.length > maxLiveEntries) liveEntriesRdo.pop();
          setData({
            ...dataRef.current,
            liveEntriesRdo: liveEntriesRdo,
            liveEntriesFilteredRdo: addDelta(filter(liveEntriesRdo, liveFilterOptionsRef.current)),
          });
          break;
        case "sat":
          const { liveEntriesSat } = dataRef.current;
          liveEntriesSat.unshift(rest);
          if (liveEntriesSat.length > maxLiveEntries) liveEntriesSat.pop();
          setData({
            ...dataRef.current,
            liveEntriesSat: liveEntriesSat,
            liveEntriesFilteredSat: addDelta(filter(liveEntriesSat, liveFilterOptionsRef.current)),
          });
          break;
      }
    });

    io.on("error", (err) => {
      console.log("ERROR: ", err.message);
      setIsBusy(false);
    });

    timeoutHandleRef.current = setTimeout(() => {
      onError("Request Timed out");
      setIsBusy(false);
    }, 60000);

    const date = moment().format("YYYY-MM-DD");
    const startDate = date;
    const endDate = startDate;

    setIsBusy(true);

    io.emit("query-get-latest", {
      startDate,
      endDate,
      max: config.maxLiveEntries,
    });

    return () => {
      io.off("result-get-latest");
      io.off("result-load-log");
      io.off("packet-parsed");
      io.off("alert-received");
      io.off("response-download-raw");
      io.off("error");
    };
  }, [config, io, onError]);

  const addDelta = (entries) => {
    return entries.map((current, i, array) => {
      const next = array[i + 1];

      if (!next) {
        return { ...current, delta: "0" };
      }

      const start = moment(current.dateTime, "DD/MM/YY HH:mm:ss");
      const end = moment(next.dateTime, "DD/MM/YY HH:mm:ss");
      const diffMs = start.diff(end);
      const duration = moment.duration(diffMs);
      const hours = Math.floor(duration.asHours());
      const days = Math.floor(duration.asDays());
      const asDate = moment.utc(diffMs);
      const delta = `${
        hours >= 24 ? (days < 10 ? `0${days}d` : `${days}d`) : hours < 10 ? `0${hours}h` : `${hours}h`
      } ${asDate.format("mm[m] ss[s]")}`;

      return { ...current, delta };
    });
  };

  const handleDownloadRaw = () => {
    const { startDate: sDate, endDate: eDate } = searchOptions;
    const startDate = sDate.format("YYYY-MM-DD");
    const endDate = eDate.format("YYYY-MM-DD");
    io.emit("query-download-raw", { startDate, endDate });
  };

  const handleSearch = () => {
    setIsBusy(true);
    setData({ ...dataRef.current, searchEntries: [] });

    timeoutHandleRef.current = setTimeout(() => {
      onError("Request Timed out");
      setIsBusy(false);
    }, 60000);

    setSearchRequested(true);
  };

  const handleSearchTypeChange = (event) => {
    setSearchOptions((prev) => {
      return { ...prev, type: event.target.value };
    });
  };

  const handleSearchFilterChange = (event) => {
    const { value, id } = event.target;

    const update = (option) => {
      setSearchOptions((prev) => {
        return { ...prev, ...option };
      });
    };

    switch (id) {
      default:
        console.log("No filter id: ", id);
        break;
      case "location":
        update({ location: value });
        break;
      case "ric":
        update({ ric: value });
        break;
      case "level":
        update({ level: value });
        break;
      case "message":
        update({ message: value });
        break;
    }
  };

  const handleClearLiveFilter = () => {
    setLiveFilterOptions(liveFilterDefaults);
  };

  const handleLiveFilterIslandChange = (event) => {
    setLiveFilterOptions((prev) => {
      return { ...prev, island: event.target.value };
    });
  };

  const handleLiveFilterChange = (event) => {
    const { value, id } = event.target;

    const update = (option) => {
      setLiveFilterOptions((prev) => {
        return { ...prev, ...option };
      });
    };

    switch (id) {
      default:
        console.log("No filter id: ", id);
        break;
      case "location":
        update({ location: value });
        break;
      case "ric":
        if (isNaN(value)) break;
        update({ ric: value });
        break;
      case "level":
        if (isNaN(value)) return false;
        update({ level: value });
        break;
      case "message":
        update({ message: value });
        break;
    }
  };

  const handleTerminalClose = () => {
    setTerminalText("");
    setIsTerminalOpen(false);
  };

  const handleTerminalClear = () => {
    setTerminalText("");
  };

  const handleStartDateChange = (date) => {
    let update = { ...searchOptions, startDate: date };

    if (date > searchOptions.endDate) {
      update = { ...update, endDate: date };
    }

    setSearchOptions(update);
  };

  const handleEndDateChange = (date) => {
    let update = { ...searchOptions, endDate: date };

    if (date < searchOptions.startDate) {
      update = { ...update, startDate: date };
    }

    setSearchOptions(update);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event) => {
    handleClose();

    const { id } = event.target;

    switch (id) {
      default:
        console.log("Menu item handle not implemented: ", id);
        break;
      case "view-live":
        setLiveView(true);
        break;
      case "search-logs":
        setLiveView(false);
        break;
      case "open-terminal":
        setIsTerminalOpen(true);
        break;
      case "print":
        setTimeout(() => window.print(), 1000);
        break;
      case "sign-out":
        onSignOut();
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isBusy}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isTerminalOpen && (
        <TerminalDialog text={terminalText} onClose={handleTerminalClose} onClear={handleTerminalClear} />
      )}
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {!liveView && (
              <MenuItem id="view-live" onClick={handleMenuItemClick}>
                Live
              </MenuItem>
            )}
            {liveView && (
              <MenuItem id="search-logs" onClick={handleMenuItemClick}>
                Logs
              </MenuItem>
            )}
            <MenuItem id="open-terminal" onClick={handleMenuItemClick}>
              Open Terminal
            </MenuItem>
            <MenuItem id="print" onClick={handleMenuItemClick}>
              Print
            </MenuItem>
            <Divider />
            <MenuItem id="sign-out" onClick={handleMenuItemClick}>
              Logout
            </MenuItem>
          </Menu>
          {liveView && (
            <Grid container>
              <Grid item xs={12} sm={1}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="filter-type">Island</InputLabel>
                  <Select
                    labelId="filter-type"
                    value={liveFilterOptionsRef.current.island}
                    onChange={handleLiveFilterIslandChange}
                  >
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="N_">North</MenuItem>
                    <MenuItem value="S_">South</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item xs={12} sm={7} alignItems="center" justify="flex-end">
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="location"
                    label="Location"
                    variant="filled"
                    value={liveFilterOptionsRef.current.location}
                    onChange={handleLiveFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="ric"
                    label="Ric"
                    variant="filled"
                    value={liveFilterOptionsRef.current.ric}
                    onChange={handleLiveFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <TextField
                    id="level"
                    label="Level"
                    variant="filled"
                    value={liveFilterOptionsRef.current.level}
                    onChange={handleLiveFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="message"
                    label="Message"
                    variant="filled"
                    value={liveFilterOptionsRef.current.message}
                    onChange={handleLiveFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Tooltip title="Clear filter" placement="right">
                    <IconButton
                      edge="start"
                      className={classes.searchIcons}
                      color="inherit"
                      aria-label="search"
                      onClick={handleClearLiveFilter}
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!liveView && (
            <Grid container>
              <Grid container item xs={12} sm={5} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture={true}
                      margin="normal"
                      id="date-picker-dialog-start"
                      label="Start Date"
                      format="DD/MM/yyyy"
                      value={searchOptions.startDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      inputProps={{
                        variant: "filled",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture={true}
                      margin="normal"
                      id="date-picker-dialog-end"
                      label="End Date"
                      format="DD/MM/yyyy"
                      value={searchOptions.endDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Tooltip title="Download Raw" placement="bottom">
                    <IconButton
                      edge="start"
                      className={classes.searchIcons}
                      color="inherit"
                      aria-label="download raw"
                      onClick={handleDownloadRaw}
                    >
                      <ArrowDropDownCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="search-type">Source</InputLabel>
                    <Select labelId="search-type" value={searchOptions.type} onChange={handleSearchTypeChange}>
                      <MenuItem value="rdo">Radio</MenuItem>
                      <MenuItem value="sat">Satellite</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={7} alignItems="center" justify="flex-end">
                <Grid item xs={12} sm={2}>
                  <TextField id="location" label="Location" variant="filled" onChange={handleSearchFilterChange} />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField id="ric" label="Ric" variant="filled" onChange={handleSearchFilterChange} />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <TextField id="level" label="Level" variant="filled" onChange={handleSearchFilterChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="message"
                    label="Message"
                    variant="filled"
                    onChange={handleSearchFilterChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Tooltip title="Search" placement="right">
                    <IconButton
                      edge="start"
                      className={classes.searchIcons}
                      color="inherit"
                      aria-label="search"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
          <CardMedia image="stjohn-sm.png" className={classes.logo} />
        </Toolbar>
      </AppBar>
      <Container component="main" className={classes.main} maxWidth={false}>
        <Grid container direction="row" justify="space-around" spacing={2}>
          {!liveView && (
            <Grid item xs={12}>
              <Typography variant="h6" color="inherit">
                Search Results
              </Typography>
              <DataTable entries={data.searchEntries} />
            </Grid>
          )}
          {liveView && (
            <SplitterLayout customClassName={classes.splitter}>
              <Container className={classes.paneContent}>
                <Typography variant="h6" color="inherit">
                  Live Radio Data
                </Typography>
                <DataTable entries={data.liveEntriesFilteredRdo} />
              </Container>
              <Container className={classes.paneContent}>
                <Typography variant="h6" color="inherit">
                  Live Satellite Data
                </Typography>
                <DataTable entries={data.liveEntriesFilteredSat} />
              </Container>
            </SplitterLayout>
          )}
        </Grid>
      </Container>
    </div>
  );
}
