export const filter = (entries, filters) => {
  const compareExact = (a, b) => {
    return !b || a === b;
  };

  const compareIncludes = (a, b) => {
    return !b || b.length < 2 || (a && a.toLowerCase().indexOf(b.toLowerCase()) > -1);
  };

  const compareStart = (a, b, ignoreCase) => {
    return !b || ignoreCase ? a && a.toLowerCase().startsWith(b.toLowerCase()) : a && a.startsWith(b);
  };

  const compareWild = (a, b) => {
    if (!b || b === "*") return true;

    const regex = new RegExp(`^${b.replace(/\*/g, "\\w+")}`, "gi");
    return regex.test(a);
  };

  const comparer = (entry) => {
    const isIsland = filters.island === "both" || compareStart(entry.location, filters.island);
    if (!isIsland) return false;

    const isLoc = compareIncludes(entry.location, filters.location);
    if (!isLoc) return false;

    const isRic = compareIncludes(entry.ric, filters.ric);
    if (!isRic) return false;

    const isLevel = compareExact(entry.level, filters.level);
    if (!isLevel) return false;

    return compareIncludes(entry.msg, filters.message);

    // console.log(`isLoc ${isLoc}, isRic ${isRic}, isLevel ${isLevel}, isMsg ${isMsg}`);
  };

  return entries.filter(comparer);
};
