import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Container, TextField, CssBaseline, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    height: 100,
    width: 120,
    backgroundSize: "contain",
  },
}));

export default function SignIn({ onSignIn, signingIn }) {
  const classes = useStyles();
  const [pwd, setPwd] = React.useState("");

  const handleChange = (e) => {
    setPwd(e.target.value);
  };

  const handleSignIn = () => {
    onSignIn(pwd);
  };

  const handleKeyUp = (e) => {
    return e.key === "Enter" && !signingIn ? handleSignIn() : undefined;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <CardMedia image="stjohn-sm.png" className={classes.logo} />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          value={pwd}
        />
        <Button
          disabled={signingIn}
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={handleSignIn}
        >
          {signingIn ? "Signing in..." : "Sign In"}
        </Button>
      </div>
    </Container>
  );
}
