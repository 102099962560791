import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 5,
    height: `calc(100vh - ${theme.spacing(21)}px)`,
  },
  container: {
    height: "inherit",
  },
  messageRow: {
    wordBreak: "break-word",
    minWidth: "200px",
  },
}));

export default function DataTable({ entries }) {
  const classes = useStyles();

  const [paging, setPaging] = useState({
    page: 0,
    rowsPerPage: 50,
    offset: 0,
  });

  useEffect(() => {
    setPaging((prev) => {
      return { ...prev, page: 0, offset: 0 };
    });
  }, [entries]);

  const handleChangePage = (_event, page) => {
    setPaging({
      ...paging,
      offset: paging.rowsPerPage * page,
      page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = +event.target.value;
    setPaging({ rowsPerPage, page: 0, offset: 0 });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Date/Time</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Ric</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Message</TableCell>
              <TableCell style={{ minWidth: "110px" }}>Delta</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries && entries.length < 1 ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <strong>No results</strong>
                </TableCell>
              </TableRow>
            ) : (
              entries &&
              entries.slice(paging.offset, paging.offset + paging.rowsPerPage).map((row, i) => {
                return (
                  <TableRow hover key={`entry-${i}`}>
                    <TableCell>{paging.offset + i + 1}</TableCell>
                    <TableCell>{row.dateTime}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.ric}</TableCell>
                    <TableCell>{row.level}</TableCell>
                    <TableCell className={classes.messageRow}>{row.msg}</TableCell>
                    <TableCell style={{ minWidth: 120 }}>{row.delta}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {entries && entries.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={entries.length}
          rowsPerPage={paging.rowsPerPage}
          page={paging.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
