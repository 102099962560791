import React, { useEffect, useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import FilterIcon from "@material-ui/icons/DeleteForever";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

const PaperComponent = (props) => {
  return <Paper {...props} />;
};

export const TerminalDialog = ({ text, onClose }) => {
  const [textFilter, setTextFilter] = useState("");
  const [filteredText, setFilteredText] = useState("");
  const [textCollection, setTextCollection] = useState([]);

  useEffect(() => {
    setTextCollection((t) => {
      // t.unshift(text.replace(/\\x0D\\x0A|\\x0D/g, "\r\n"));
      if (t.length > 100) return [text];
      else {
        t.unshift(text);
        return t;
      }
    });

    if (textFilter.length >= 3) {
      const filtered = textCollection.filter((entry) => entry.toLowerCase().indexOf(textFilter.toLowerCase()) > -1);
      setFilteredText(filtered.join("\r"));
    } else {
      setFilteredText(textCollection.join("\r"));
    }
  }, [text, textFilter, textCollection]);

  const useStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textInput: {
      "& textarea": {
        fontFamily: "monospace",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Dialog open={true} fullScreen onClose={onClose} PaperComponent={PaperComponent} scroll="paper">
      <MuiDialogTitle disableTypography className={classes.root}>
        <Toolbar>
          <TextField
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            label="Filter"
            className={classes.filterInput}
            variant="filled"
          />
          <Tooltip title="Clear filter" placement="right">
            <IconButton color="inherit" aria-label="search" onClick={() => setTextFilter("")}>
              <FilterIcon />
            </IconButton>
          </Tooltip>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </MuiDialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          multiline
          value={filteredText}
          InputProps={{
            disableUnderline: true,
          }}
          className={classes.textInput}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setTextCollection([]);
            setFilteredText("");
          }}
          color="primary"
        >
          Clear
        </Button>
      </DialogActions>
    </Dialog>
  );
};
